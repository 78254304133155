




















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalGetters, GlobalActions } from "@/store/modules/global/types";
@Component
export default class NotFound extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;
  protected showError = false;
  protected messageHtmlError = "";

  mounted(): void {
    if (this.$route.query.error) {
      this.messageHtmlError = decodeURI(this.$route.query.error as string);
      this.showError = true;
    }
  }

  protected closeToastError(): void {
    this.showError = false;
  }
}
